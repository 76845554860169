<template>
	<div class="grid p-fluid">
        <Toast/>
        <div class="col-12">
			<div class="card" :style="{'background-color':this.cardBackgroundColor}">
                <div style="width: 100%; display: flex;">
                    <h2 style="font-size: large;">{{ (this.sale.edoc === null) ? "Venta" : "Factura" }} - {{ this.$store.getters.defaultActivity.name }}</h2>
                    <Button v-show="showNewButton" icon="pi pi-plus" class="newsale-button p-button-rounded p-button-success mr-2 mb-2" style="margin-left: auto;" @click="newSale"/>
                </div>
				<div class="p-fluid formgrid grid">
					<div class="field col-12">
                        <div class="grid formgrid" style="margin-top: 10px;">
                            <div class="col-12">
                                <div class="card">
                                    <h5>Datos del Cliente</h5>
                                    <Button icon="pi pi-search" v-if="this.sale.editable" class="p-button-rounded p-button-info mr-2 mb-2" style="position: absolute; top: 20px; right: 20px;" @click="showCustomerDialog"/>
                                    <div class="grid formgrid">
                                        <div class="col-12 mb-2 lg:col-6 lg:mb-0">
                                            <label for="customerName">Razón Social</label>
                                            <InputText autofocus v-model="this.sale.customer.name" id="customerName" type="text" :class="{'p-invalid': this.nameError}" @change="checkForm()" :disabled="!this.sale.editable"/>
                                            <small class="p-invalid" v-if="this.nameError">Ingrese el nombre o razón social</small>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-6 lg:mb-0">
                                            <label for="customerId">Cédula/RUC/Pasaporte</label>
                                            <InputText v-model="this.sale.customer.identification" id="customerId" type="text" :class="{'p-invalid': this.identificationError}" @change="checkForm()" :disabled="!this.sale.editable" />
                                            <small class="p-invalid" v-if="this.identificationError">La cédula debe tener 10 dígitos y el RUC 13 dígitos</small>
                                        </div>
                                    </div>
                                    <div class="grid formgrid">
                                        <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                            <label for="customerEmail" style="margin-top: 15px;">Correo</label>
                                            <InputText v-model="this.sale.customer.email" id="customerEmail" type="email" :disabled="!this.sale.editable"/>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                            <label for="customerPhone" style="margin-top: 15px;">Teléfono</label>
                                            <InputText v-model="this.sale.customer.phone" id="customerPhone" type="number" :disabled="!this.sale.editable"/>
                                        </div>
                                        <div class="col-12 mb-2 lg:col-4 lg:mb-0">
                                            <label for="customerAddress" style="margin-top: 15px;">Dirección</label>
                                            <InputText v-model="this.sale.customer.address" id="customerAddress" type="text" :class="{'p-invalid': this.addressError}" @change="checkForm()" :disabled="!this.sale.editable"/>
                                            <small class="p-invalid" v-if="this.addressError">Ingrese una dirección</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="grid formgrid" style="margin-top: 20px;">
                            <div class="col-12">
                                <div class="card">
                                    <h5>Producto(s) / Servicio(s)</h5>
                                    <Button icon="pi pi-th-large" v-if="this.sale.editable && this.$store.getters.business.businessType === 'restaurant'" class="p-button-rounded p-button-info mr-2 mb-2" style="position: absolute; top: 15px; right: 20px;" @click="showProductMenuDialog"/>
                                    <Button icon="pi pi-search" v-if="this.sale.editable && this.$store.getters.business.businessType !== 'restaurant'" class="p-button-rounded p-button-info mr-2 mb-2" style="position: absolute; top: 15px; right: 20px;" @click="showProductDialog"/>
                                    <DataTable :value="this.sale.items" editMode="cell" @cell-edit-complete="onAmountChanged" :scrollable="true" scrollHeight="1200px" scrollDirection="both" class="mt-3 editable-cells-table">
                                        <Column field="productId" header="Cód." :style="{width: '10%','min-width':'50px'}">
                                            <template #body="slotProps">
                                                {{ slotProps.data.code !== undefined && slotProps.data.code !== null ? slotProps.data.code : slotProps.data.productId  }}
                                            </template>
                                        </Column>
                                        <Column field="amount" header="Cant." :style="{width: '12.5%','min-width':'100px'}">
                                            <template #editor="{data, field}" v-if="this.sale.editable && this.$store.getters.business.businessType !== 'restaurant'">
                                                <InputText v-model="data[field]" type="number" autofocus :disabled="!this.sale.editable"/>
                                            </template>
                                        </Column>
                                        <Column field="product" header="Descr." :style="{width: '30%','min-width':'75px'}"></Column>
                                        <Column field="readableTax" header="IVA" :style="{width: '10%','min-width':'50px'}"></Column>
                                        <Column field="unitPrice" header="Precio Unit." :style="{width: '12.5%','min-width':'50px'}">
                                            <template #editor="{data}" v-if="this.sale.editable">
                                                <Dropdown v-model="data.unitPrice" :options="data.priceList" :disabled="!this.sale.editable"/>
                                            </template>
                                        </Column>
                                        <Column field="readableDiscount" header="Descto. %" :style="{width: '12.5%','min-width':'100px'}">
                                            <template #editor="{data, field}" v-if="this.sale.editable">
                                                <InputText v-model="data[field]" type="number" autofocus :disabled="!this.sale.editable" @change="onDiscountChanged(data)"/>
                                            </template>
                                        </Column>
                                        <Column field="totalPrice" header="Precio Final" :style="{width: '10%','min-width':'50px'}">
                                            <template #body="slotProps">
                                                {{(slotProps.data.totalPrice).toFixed(2)}}
                                            </template>
                                        </Column>
                                        <Column :exportable="false" style="width: 50px;">
                                            <template #body="item" v-if="this.sale.editable">
                                                <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text mr-2 mb-2"  @click="onItemDeleted(item)"/>
                                            </template>
                                        </Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>

                        <div class="grid formgrid" style="margin-top: 20px;">
                            <div class="col-12">
                                <div class="card">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-6">
                                            <h5 v-if="this.sale.editable || (this.sale.editable === false && this.sale.observations)">Observaciones</h5>
                                            <Textarea v-if="this.sale.editable || (this.sale.editable === false && this.sale.observations)" :disabled="!this.sale.editable" id="observations" rows="2" v-model="this.sale.observations" style="margin-bottom: 10px;"/>
                                            <div class="paymentContainer">
                                                <div class="col-12 mb-2 lg:col-6 lg:mb-0">
                                                    <h5>Plazo <label v-if="this.sale.creditDays>0"><i class="dueDateLabel" :style="{'color' : (!this.sale.isPaid) ? 'red' : 'black'}">{{this.sale.collectStatusReadable === "VENCIDA" ? 'Vencida' : 'Vence'}} el {{ this.sale.dueDateFormatted }}</i></label></h5>
                                                    <div class="paymentSubcontainer">
                                                        <div style="width: 100%;">
                                                            <Dropdown v-model="creditDay" :options="creditDays" optionLabel="name" @change="onCreditDaysChanged" placeholder="Días de plazo" :disabled="!this.sale.editable" />
                                                        </div>
                                                        <div class="col-12 mb-2 lg:col-12 lg:mb-0 collectButton" v-if="!this.sale.editable && this.sale.creditDays>0">
                                                            <Button :label="(this.sale.collectStatusReadable === 'COBRADA') ? 'Cobrado' : 'Cobrar'" :icon="(this.sale.collectStatusReadable === 'COBRADA') ? 'pi pi-check' : ''" :class="{'p-button-outlined': this.sale.collectStatusReadable === 'COBRADA', 'p-button-success': true, 'mr-2': true, 'mb-2': true}" :disabled="this.sale.collectStatusReadable === 'COBRADA'" @click="collectSale()" :loading="this.loadingCollectButton"/>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                <h5 style="margin-bottom: 5px;">Pago</h5>
                                                <Dropdown v-model="this.sale.paymentMethod" :options="paymentMethods" optionLabel="name" placeholder="Forma de pago" :disabled="!this.sale.editable"/>
                                            </div>
                                            <div v-if="this.sale.edoc !== null" style="margin-top: 10px;">
                                                <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                    <h5 style="margin: 5px 0px;">Secuencial</h5>
                                                    <h6 style="margin: 0px; font-weight: 300;">{{ this.sale.edoc.sequence }}</h6>
                                                </div>
                                                <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                    <h5 style="margin: 10px 0px 5px 0px;">Fecha Emisión</h5>
                                                    <label style="margin: 0px; font-weight: 300;">{{ (this.sale.edoc !== null && this.sale.edoc.sentAt !== null) ? this.sale.edoc.sentAt.toLocaleDateString("es-ES") : "-" }}</label>
                                                </div>
                                                <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                    <h5 style="margin: 10px 0px 5px 0px;">Fecha Autorización</h5>
                                                    <label style="margin: 0px; font-weight: 300;">{{ (this.sale.edoc !== null && this.sale.edoc.authorizedAt !== null) ? this.sale.edoc.authorizedAt.toLocaleDateString("es-ES") : "-" }}</label>
                                                </div>
                                                <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                                    <h5 style="margin: 5px 0px;">Código de acceso</h5>
                                                    <h6 style="margin: 0px; font-weight: 300;">{{ this.sale.edoc.accessCode }}</h6>
                                                </div>
                                                <div class="col-12 mb-2 lg:col-12 lg:mb-0" v-if="this.sale.edoc.nulled === true && this.sale.edoc.received === true">
                                                    <h5 style="margin: 20px 0px 0px 0px;"><b>Esta factura debe ser anulada en el portal del SRI</b></h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="field col-12 md:col-6 saleInformationContainer">
                                            <div style="display: flex;">
                                                <label>Subtotal 0%</label>
                                                <label style="margin: 0px 0px 0px auto;">{{ this.sale.sub0.toFixed(2) }}</label>
                                            </div>
                                            <div style="display: flex;">
                                                <label>Subtotal {{ this.sale.taxPercentage }}%</label>
                                                <label style="margin: 0px 0px 0px auto;">{{ this.sale.sub12.toFixed(2) }}</label>
                                            </div>
                                            <div style="display: flex;">
                                                <label>Subtotal sin impuestos</label>
                                                <label style="margin: 0px 0px 0px auto;">{{ (this.sale.sub0 + this.sale.sub12).toFixed(2) }}</label>
                                            </div>
                                            <div style="display: flex;">
                                                <label>IVA ({{ this.sale.taxPercentage }}%)</label>
                                                <label style="margin: 0px 0px 0px auto;">{{ (this.sale.tax).toFixed(2) }}</label>
                                            </div>
                                            <div style="display: flex;">
                                                <label>Descuento</label>
                                                <label style="margin: 0px 0px 0px auto;">{{ this.sale.discount.toFixed(2) }}</label>
                                            </div>
                                            <div style="display: flex;">
                                                <label>Total</label>
                                                <label style="margin: 0px 0px 0px auto;">{{ this.sale.total.toFixed(2) }}</label>
                                            </div>
                                        </div>
                                        <div class="field col-12 md:col-12 saleFormButtonContainer" style="margin-top: 35px;" v-if="this.nulled === false">
                                            <Button label="Limpiar" v-show="showClearButton" class="localButton p-button-raised p-button-danger mr-2 mb-2"  @click="clearSale"/>
                                            <Button label="Anular" v-show="showDeleteButton" class="localButton p-button-raised p-button-danger mr-2 mb-2"  @click="displayDeleteSaleConfirmation(true)"/>
                                            <div style="margin-left: auto;"></div>
                                            <Button label="Guardar" v-show="showSaveButton" :loading="loadingSaveButton" class="localButton p-button-raised p-button-secondary mr-2 mb-2"  @click="saveSale(false, true)"/>
                                            <Button label="Archivar" v-show="showArchiveButton" :loading="loadingArchiveButton" class="localButton p-button-raised p-button-primary mr-2 mb-2"  @click="saveSale(false, false)"/>
                                            <Button label="Facturar" v-show="showReceiptButton" :loading="loadingReceiptButton" class="localButton p-button-raised p-button-success mr-2 mb-2"  @click="saveSale(true, false)" />
                                            <Button label="Imprimir" v-show="showPrintButton" :loading="loadingPrintButton" class="localButton p-button-raised p-button-success mr-2 mb-2"  @click="printSale()" />
                                            <Menu ref="menu" :model="downloadOptions" :popup="true" class="localButton">
                                                <template #item="{item}">
                                                    <Button type="button" :label="item.label" :icon="item.icon" class="menuButton mr-2 mb-2" :loading="loadingDownloadReceiptButton" @click="downloadReceipt(item.name)"/>
                                                </template>
                                            </Menu>
                                            <Button type="button" label="Descargar" icon="pi pi-angle-down" v-show="showDownloadReceiptButton" class="localButton p-button-raised p-button-success mr-2 mb-2" @click="toggleDownloadMenu"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Dialog header="Aviso" v-model:visible="receiptDialog" :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" :modal="true">
                            <p class="line-height-3 m-0">
                                {{ this.error.title }}<br>
                                {{ this.error.mensaje }}<br>
                                {{ this.error.informacionAdicional }}
                            </p>
                            <template #footer>
                                <Button label="Entendido" @click="closeReceiptDialog" icon="pi pi-check" class="p-button-outlined"/>
                            </template>
                        </Dialog>

                        <Dialog header="Advertencia" v-model:visible="subscriptionDialog" :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" :modal="true">
                            <p class="line-height-3 m-0">
                                {{ this.error.title }}<br>
                                {{ this.error.mensaje }}<br>
                                {{ this.error.informacionAdicional }}
                            </p>
                            <template #footer>
                                <Button label="Cerrar" @click="closeSubscriptionDialog" class="p-button-secondary mr-2 mb-2"/>
                                <Button label="Ver suscripción" @click="redirectToSubscription" class="p-button-info mr-2 mb-2"/>
                            </template>
                        </Dialog>

                        <Dialog header="Confirmación" v-model:visible="showDeleteSaleConfirmation" :style="{width: '350px'}" :modal="true">
                            <div class="flex align-items-center justify-content-center">
                                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                                <span>¿Está seguro de anular esta venta?</span>
                            </div>
                            <template #footer>
                                <Button label="No" @click="displayDeleteSaleConfirmation(false)" class="p-button-secondary mr-2 mb-2"/>
                                <Button label="Sí" @click="deleteSale" class="p-button-warning mr-2 mb-2" autofocus />
                            </template>
                        </Dialog>

                        <Dialog v-model:visible="customerDialog" :style="{'max-width': '450px', width: '90%'}" header="Buscar Cliente" :modal="true" class="p-fluid">
                            <Listbox autofocus :modelValue="selectedCustomer" :options="customerOptions" optionLabel="name" :filter="true" :filterFields="customerFilterFields" @filter="onCustomerFilterChanged" @change="onCustomerSelected"/>
                            <template #footer>
                                <Button label="Cerrar" class="p-button-secondary mr-2 mb-2" @click="hideCustomerDialog"/>
                            </template>
                        </Dialog>

                        <Dialog v-model:visible="productDialog" :style="{'max-width': '550px', width: '90%'}" header="Buscar Producto" :modal="true" class="p-fluid">
                            <AutoComplete autofocus placeholder="Buscar por nombre..." id="dd" :dropdown="false" :multiple="false" v-model="searchedProduct" @complete="onProductFilterChanged($event)" inputStyle="width: 100%;" style="width: 100%; margin-top: 5px;" :selectOnFocus="true"/>
                            <DataTable :value="this.productOptions" v-model:selection="selectedProduct" selectionMode="single" @rowSelect="onProductSelected" :scrollable="true" scrollHeight="400px" scrollDirection="both" class="mt-3 editable-cells-table">
                                <Column field="code" header="Cód." :style="{width: '25%','min-width':'70px'}"></Column>
                                <Column field="name" header="Nombre." :style="{width: '50%','min-width':'100px'}"></Column>
                                <Column field="stock" header="Stock" :style="{width: '5%','min-width':'60px'}">
                                    <template #body="slotProps">
                                        {{ (slotProps.data.stock !== undefined && slotProps.data.stock !== null) ? slotProps.data.stock : "-" }}
                                    </template>
                                </Column>
                                <Column field="price" header="Precio" :style="{width: '10%','min-width':'70px'}">
                                    <template #body="slotProps">
                                        ${{ slotProps.data.price }}
                                    </template>
                                </Column>
                            </DataTable>
                            <template #footer>
                                <Button label="Cerrar" class="p-button-secondary mr-2 mb-2" @click="hideProductDialog"/>
                            </template>
                        </Dialog>

                        <Dialog v-model:visible="productMenuDialog" :style="{'max-width': '900px', width: '90%'}" header="Seleccione productos" :modal="true" class="p-fluid" :dismissableMask="true">
                            <div class="col-12">
                                <div class="card">
                                    <DataView :value="this.productOptions" layout="grid" :paginator="true" :rows="9">
                                        <template #header>
                                            <div class="grid grid-nogutter">
                                                <AutoComplete placeholder="Buscar producto..." :dropdown="false" :multiple="false" v-model="searchedProduct" @complete="onProductFilterChanged($event)" inputStyle="width: 100%;" style="width: 100%; margin-top: 5px;" :selectOnFocus="true"/>
                                            </div>
                                        </template>
                                        <template #grid="slotProps">
                                            <div class="col-12 md:col-4">
                                                <div class="card m-3 border-1 surface-border">
                                                    <div class="flex align-items-center justify-content-between">
                                                        <div class="flex align-items-center">
                                                            <i class="pi pi-tag mr-2"></i>
                                                            <span class="font-semibold">{{slotProps.data.category}}</span>
                                                        </div>
                                                        <span v-if="slotProps.data.hasStock && this.isAuthorized" :class="'product-badge status-' + slotProps.data.stockBadge">{{ slotProps.data.stock }}</span>
                                                    </div>
                                                    <div class="text-center">
                                                        <img :src="(slotProps.data.imageUrl !== null) ? (this.$baseURL + slotProps.data.imageUrl) : ('../../images/product.png')" :alt="slotProps.data.name" class="w-9 shadow-2 my-3 mx-0"/>
                                                        <div class="text-2xl font-bold" >{{slotProps.data.name}}</div>
                                                        <div class="flex align-items-center justify-content-between" style="margin-top: 0.5rem;">
                                                            <Button icon="pi pi-minus" class="p-button-rounded p-button-info mr-2 mb-2" @click="onSelectedAmountChanged(slotProps.data, -1)"/>
                                                            <h4 style="margin-top: 0.2rem;">{{slotProps.data.selectedAmount}}</h4>
                                                            <Button icon="pi pi-plus" class="p-button-rounded p-button-info mr-2 mb-2" @click="onSelectedAmountChanged(slotProps.data, 1)"/>
                                                        </div>
                                                    </div>
                                                    <div class="flex align-items-center justify-content-between" style="margin-top: 0.5rem;">
                                                        <Menu ref="priceMenu" :model="slotProps.data.priceMap" :popup="true" style="width: 100px; background-color: lightgrey;">
                                                            <template #item="{item}">
                                                                <Button type="button" :label="item.label" class="menuButton mr-2 mb-2"/>
                                                            </template>
                                                        </Menu>
                                                        <span class="text-2xl font-semibold" @click="onPriceSelected">${{ (slotProps.data.price * slotProps.data.selectedAmount).toFixed(2) }}</span>
                                                        <Button :class="{ productSelected: slotProps.data.productSelected }" icon="pi pi-shopping-cart" @click="onProductSelected(slotProps)"></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </DataView>
                                </div>
                            </div>
                            <template #footer>
                                <Button label="Cerrar" class="p-button-secondary mr-2 mb-2" @click="hideProductMenuDialog"/>
                            </template>
                        </Dialog>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>

import download from 'downloadjs'
import httpRequest from '../../service/httpRequest';
import Sale from '../../models/sale';
import SaleItem from '../../models/saleItem';
import Customer from '../../models/customer';
import Product from '../../models/product';

export default {
	data() {
		return {
            sale: new Sale(),
            cardBackgroundColor: "white",
            nulled: false,
            editable: true,
            customerOptions: [],
            customerFilterFields: ['name', 'identification', 'email'],
            selectedCustomer: null,
            customerDialog: false,
            productOptions: [],
            selectedProduct: null,
            searchedProduct: null,
            productDialog: false,
            productMenuDialog: false,
            receiptDialog: false,
            subscriptionDialog: false,
            error: {},
            nameError: false,
			identificationError: false,
			addressError: false,
            scannerBuffer: new String(),
            isAuthorized: true,

            // Show
            showNewButton: false,
            showClearButton: false,
            showSaveButton: false,
            showArchiveButton: false,
            showReceiptButton: false,
            showDeleteButton: false,
            showDeleteSaleConfirmation: false,
            showPrintButton: false,
            showDownloadReceiptButton: false,

            // Loading
            loadingSaveButton: false,
            loadingArchiveButton: false,
            loadingReceiptButton: false,
            loadingCollectButton: false,
            loadingPrintButton: false,
            loadingDownloadReceiptButton: false,
            
            paymentMethods: [
                {name: 'Efectivo', code: 'CASH'},
                {name: 'Transferencia', code: 'TRANSFER'},
                {name: 'Tarjeta Débito', code: 'DEBIT_CARD'},
                {name: 'Tarjeta Crédito', code: 'CREDIT_CARD'},
                {name: 'Cheque', code: 'CHECK'},
            ],
            creditDays: [
                {name: '0 días', code: '0'},
                {name: '1 días', code: '1'},
                {name: '3 días', code: '3'},
                {name: '7 días', code: '7'},
                {name: '14 días', code: '14'},
                {name: '21 días', code: '21'},
                {name: '30 días', code: '30'},
                {name: '45 días', code: '45'},
                {name: '60 días', code: '60'},
                {name: '90 días', code: '90'},
            ],
            creditDay: {name: '0 días', code: '0'},

            // Download
            downloadOptions: [
                {
                    label: 'PDF',
                    name: 'pdf',
                    icon: 'pi pi-file-pdf'
                },
                {
                    label: 'XML',
                    name: 'xml',
                    icon: 'pi pi-file-excel'
                },
            ],
		}
	},
    async created() {
        window.scrollTo(0, 0);

        // Setup accordingly to store setup
        this.isAuthorized = await this.$store.getters.getUser.role === "Administrador";
        if (await this.$store.getters.business.businessType === 'restaurant') { this.fetchMenu(); }

        // Get sale
        let saleId = this.$route.query.id;
        if (saleId !== null && saleId !== undefined) {
            let response = await httpRequest.getSale(saleId);
            if (response.status == 200) {
                this.sale = new Sale(response.data);
                this.setupUI();
                // Setup duedate/creditdays dropdown
                this.creditDay = this.getCreditDayDic(this.sale.creditDays);
            }
        } else { this.setupUI(); }

        // Load sale from proforma
        let proformaId = this.$route.query.proformaId;
        if (proformaId !== null && proformaId !== undefined) { this.getProforma(proformaId) }
        
        // Register event listener for scanner
        document.addEventListener('keydown', this.onScanned);
    },
    beforeUnmount() {
        document.removeEventListener('keydown', this.onScanned);
    },
	methods: {
        async onScanned(event) {
            var charCode = (typeof event.which === "number") ? event.which : event.key
            if (charCode !== 13) {
                this.scannerBuffer += String.fromCharCode(charCode);
            } else {
                let filter = this.scannerBuffer.replace('undefined', '');
                this.scannerBuffer = "";
                if (filter > 4) {
                    const result = await httpRequest.getProducts(filter);
                    if (result.status === 200 && result.data.length === 1) {
                        const product = new Product(result.data[0]);
                        this.onProductSelected({data: product});
                    }
                    return;
                }
            }
        },
        async setupUI() {
            if (this.sale.edoc !== null) { 
                this.nulled = this.sale.edoc.nulled;
                this.cardBackgroundColor = (this.sale.edoc.nulled === true) ? "#ffc9c9" : "white";
            } else {
                this.nulled = false;
                this.cardBackgroundColor = "white";
            }
        
            this.showNewButton = this.sale.id !== null;
            this.showClearButton = this.sale.id === null;
            this.showDeleteButton = this.sale.id !== null && this.isAuthorized;
            this.showSaveButton = this.sale.editable;
            this.showArchiveButton = this.sale.editable;
            this.showReceiptButton = this.sale.edoc === null && (await this.$store.getters.business.certName !== "Seleccionar");
            this.showPrintButton = (await this.$store.getters.hasDevices) && ((!this.sale.editable) || (await this.$store.getters.business.businessType === 'restaurant' && this.sale.id !== null));
            this.showDownloadReceiptButton = this.sale.edoc !== null;
            this.showDownloadXMLReceiptButton = this.sale.edoc !== null;
        },
        newSale() {
            let path = "/dashboard/venta/form"
            history.pushState(
                {},
                null,
                path
            )
            this.$router.go(path);
        },  

        // Proforma
        async getProforma(proformaId) {
            let response = await httpRequest.getProforma(proformaId);
            if (response.status == 200) {
                this.sale = new Sale(response.data);
                this.sale.loadFromProforma(response.data.id);
                this.setupUI();
                // Setup duedate/creditdays dropdown
                this.creditDay = this.getCreditDayDic(this.sale.creditDays);
            }
        },

        // Customer
        checkForm() {
			var error = false;
			if (this.sale.customer.name === null || this.sale.customer.name === undefined || this.sale.customer.name === "") {
				this.nameError = true;
				error = true;
			} else { this.nameError = false }
			if (this.sale.customer.identification === null || this.sale.customer.identification === undefined ) {
				this.identificationError = true;
				error = true;
			} else if (/^\d+$/.test(this.sale.customer.identification) && this.sale.customer.identification.length !== 10 && this.sale.customer.identification.length !== 13) {
				this.identificationError = true;
				error = true;
			} else { this.identificationError = false }
			if (this.sale.customer.address === null || this.sale.customer.address === undefined || this.sale.customer.address === "") {
				this.addressError = true;
				error = true;
			} else { this.addressError = false }
			return error;
		},
        showCustomerDialog() {
            this.customerDialog = true;
        },
        hideCustomerDialog() {
            this.customerDialog = false;
        },
        async onCustomerFilterChanged(event) {
            let filter = event.value;
            if (filter.length > 1) {
                const result = await httpRequest.getCustomers(filter);
                if (result.status === 200) {
                    this.customerOptions = result.data.map(customer => new Customer(customer));
                } else { this.customerOptions = [] }
            }
        },
        onCustomerSelected(event) {
            let customer = new Customer(event.value)
            this.sale.customer = customer;
            this.selectedCustomer = null;
            this.customerDialog = false;
        },

        // Product
        showProductDialog() {
            this.productDialog = true;
        },
        showProductMenuDialog() {
            this.productMenuDialog = true;
        },  
        hideProductDialog() {
            this.productDialog = false;
        },
        hideProductMenuDialog() {
            this.productMenuDialog = false;
        },
        async onProductFilterChanged(event) {
            let filter = event.query;
            if (filter.length > 1) {
                const result = await httpRequest.getProducts(filter);
                if (result.status === 200) {
                    this.productOptions = result.data.map(product => new Product(product));
                } else { this.productOptions = [] }
            }
        },
        async fetchMenu() {
            const result = await httpRequest.getProducts(null, 'code');
            if (result.status === 200) {
                this.productOptions = result.data.map(product => new Product(product));
            } else { this.productOptions = [] }
        },
        onProductSelected(data) {
            let product = new Product(data.data);
            let object = {
                productId: product.id,
                code: product.code,
                product: product.name,
                productObj: new Product(product),
                hasTax: product.hasTax,
                discount: product.discount,
                unitPrice: product.price,
                totalPrice: product.price,
                priceList: product.priceList,
                amount: product.selectedAmount
            }
            
            let item = new SaleItem(object);
            this.sale.updateItems(item);
            
            this.selectedProduct = null;
            this.productDialog = false;

            // Animate selection
            data.data.selectedAmount = 1;
            data.data.productSelected = true;
            setTimeout(() => {
                data.data.productSelected = false
            }, 500)
        },
        onAmountChanged(event) {
            let item = new SaleItem(event.newData);
            this.sale.updateItems(item, false);
        },
        onSelectedAmountChanged(data, amount) {
            if (amount < 0 && data.selectedAmount == 1) { return }
            data.selectedAmount += amount;
        },
        onSelectedPriceChanged(product, price) {
            product.price = price;
        },
        onPriceSelected(event) {
            this.$refs.priceMenu.toggle(event);
        },
        onItemDeleted(item) {
            this.sale.deleteItem(item.data);
        },
        onDiscountChanged(data) {
            data.discount = data.readableDiscount / 100;
            let item = new SaleItem(data);
            this.sale.updateItems(item, false);
        },

        // Sale
        clearSale() {
            this.sale = new Sale();
        },
        async saveSale(makeReceipt, editable) {
            let result = this.checkForm();
            if (!result) {
                // Make SRI verification if making receipt
                if (makeReceipt && this.sale.total >= 50 && this.sale.customer.isConsumidorFinal) {
                    this.$toast.add({severity:'error', summary: 'Debe agregar un cliente', detail: 'Por reglamento del SRI las ventas mayores a 50 USD no pueden facturarse a nombre de CONSUMIDOR FINAL', life: 7000});
                    return;
                }

                if (this.sale.items !== undefined && this.sale.items.length > 0) {
                    // Enable loading mode
                    this.loadingSaveButton = editable === true;
                    this.loadingArchiveButton = makeReceipt === false && editable === false ;
                    this.loadingReceiptButton = makeReceipt === true;

                    let isNewSale = this.sale.id === null;
                    this.sale.editable = editable;
                    this.sale.paymentMethod = this.sale.paymentMethod.code;
                    let response = await httpRequest.saveSale(this.sale, makeReceipt);

                    // Disable loading mode
                    this.loadingSaveButton = false;
                    this.loadingArchiveButton = false;
                    this.loadingReceiptButton = false;

                    if (response.data !== undefined && response.data !== null) {
                        this.sale = new Sale(response.data);
                        this.setupUI();
                        history.pushState(
                            {},
                            null,
                            this.$route.path + '?id=' + this.sale.id
                        )
                    }
                    if (response.status === 200) {
                        if (this.sale.edoc !== null) {
                            if (this.sale.customer.email !== null) { // Response if e-receipt is sent to an email
                                this.$toast.add({severity:'success', summary: 'Factura enviada', detail: 'Su factura ha sido emitida y enviada exitosamente al correo: ' + this.sale.customer.email, life: 3000});
                            } else { // Default success e-receipt answer
                                this.$toast.add({severity:'success', summary: 'Factura emitida', detail: 'Su venta ha sido facturada', life: 3000});
                            }
                        } else {
                            this.$toast.add({severity:'success', summary: 'Éxito', detail: 'Su venta ha sido guardada', life: 3000});
                        }
                    } else if (response.status === 600 || response.status === 601) {
                        this.error = response.error;
                        this.error.title = "Su venta ha sido guardada éxitosamente, pero hemos tenido los siguientes problemas generar su factura:";
                        this.receiptDialog = true;
                    }  else if (response.status === 602) {
                        if (isNewSale) { 
                            this.error.title = "Su venta ha sido guardada, pero hemos tenido un problema facturando su venta:";
                            this.error.mensaje = "Su certificado o la contraseña del certificado no es válida."
                        } else { 
                            this.error.title = "Hemos tenido un problema facturando su venta:";
                            this.error.mensaje = "Su certificado o la contraseña del certificado no es válida."
                        }
                        this.receiptDialog = true;
                    } else if (response.status === 603) {
                        this.$toast.add({severity:'warn', summary: 'Servicio del SRI no disponible', detail:'Su factura ha sido generada con éxito, y podrá reportar su factura al SRI cuando el servicio este activo nuevamente.', life: 10000});
                    } else if (response.status === 802) { 
                        this.error.title = (response.error === "NO_DOC") ? "Documentos electrónicos agotados" : "Suscripción no válida";
                        this.error.mensaje = "Su venta ha sido guardada por el momento. Para facturar por favor renueve su suscripción."
                        this.subscriptionDialog = true;
                    } else {
                        this.$toast.add({severity:'error', summary: 'Error interno', detail: 'Hemos detectado un error, por favor intente nuevamente.', life: 3000});
                    }
                } else {
                    this.$toast.add({severity:'error', summary: 'Error guardando venta', detail: 'Agregue uno o mas productos', life: 3000});
                }
            }
        },
        async collectSale() {
            let response = await httpRequest.collectSale(this.sale.id);
            if (response.status === 200) {
                if (response.data !== null) {
                    this.sale = new Sale(response.data);
                    this.setupUI();
                    this.$toast.add({severity:'success', summary: 'Venta cobrada', detail: 'Su venta ha sido marcada como cobrada', life: 3000});
                }
            } else {
               this.$toast.add({severity:'error', summary: 'Error cobrando venta', detail: 'Por favor intente nuevamente, si el error continua comuníquese con el proveedor.', life: 3000}); 
            }
        },
        async deleteSale() {
            let response = await httpRequest.deleteSale(this.sale.id);
            if (response.status === 200) {
                if (response.data !== null) {
                    this.sale = new Sale(response.data);
                    this.setupUI();
                } else {
                    this.$router.push('/dashboard/venta/crud');
                }
            } else {
               this.$toast.add({severity:'error', summary: 'Error anulando venta', detail: 'Por favor intente nuevamente, si el error continua comuníquese con el proveedor.', life: 3000}); 
            }
            this.showDeleteSaleConfirmation = false;
        },
        onCreditDaysChanged() {
            this.sale.creditDays = parseInt(this.creditDay.code);
        },
        getCreditDayDic(creditDays) {
            for (var i in this.creditDays) {
                if (this.creditDays[i].code == creditDays) { return this.creditDays[i]; }  
            }
            return this.creditDays[0];
        },
        displayDeleteSaleConfirmation(show) {
            this.showDeleteSaleConfirmation = show;
        },
        closeReceiptDialog() {
            this.receiptDialog = false;
        },
        closeSubscriptionDialog() {
            this.subscriptionDialog = false;
        },
        redirectToSubscription() {
            this.subscriptionDialog = false;
            this.$router.push('/dashboard/general/suscripcion');
        },

        // Receipt
        toggleDownloadMenu(event) {
            this.$refs.menu.toggle(event);
        },
        async downloadReceipt(type) {
            if (type === 'pdf') {
                this.loadingDownloadReceiptButton = true;
                const response = await httpRequest.downloadSaleEdoc(this.sale.edoc.id, type);
                download(response, "factura_" + this.sale.edoc.serial + ".pdf", "application/pdf");
                this.loadingDownloadReceiptButton = false;
            } else if (type === 'xml') {
                this.loadingDownloadXMLReceiptButton = true;
                const response = await httpRequest.downloadSaleEdoc(this.sale.edoc.id, type);
                download(response, "factura_" + this.sale.edoc.serial + ".xml", "application/xml");
                this.loadingDownloadXMLReceiptButton = false;
            }
        },

        async printSale() {
            this.loadingPrintButton = true;
            const response = await httpRequest.printSale(this.sale.id);
            if (response.status == 200) {
                this.$toast.add({severity:'success', summary: 'Imprimiendo...', detail: '', life: 3000}); 
            } else if (response.status == 701) {
                this.$toast.add({severity:'error', summary: 'Dispositivo no conectado', detail: "Asegurese que su dispositivo este conectado y configurado", life: 10000}); 
            } else {
                let error = (response !== undefined && response.error !== undefined) ? response.error : "Por favor contacte a soporte para la solución de este problema."
                this.$toast.add({severity:'error', summary: 'Error imprimiendo', detail: error, life: 10000}); 
            }
            this.loadingPrintButton = false;
        }
	}
}
</script>

<style scoped lang="scss">

@import '../../assets/demo/badges.scss';

    .menuButton {
        width: 100%;
        background-color: white;
        border: 0px;
        color: darkgrey;
    }
    .menuButton:hover {
        background-color: gray;
    }
    .newsale-button {
        position: absolute;
        top: 13px;
        right: 10px;
    }
    .p-menu {
        width: 40px;
    }
    .dueDateLabel {
        font-size: small;
        font-weight: 200;
    }
    .productSelected {
        background-color: green !important;
    }
    @media screen and (max-width: 769px) {
        .saleFormButtonContainer {
            display: block;
        }
        .paymentContainer {
            display: initial;
        }
        .paymentContainer > div > h5 {
            margin: 10px 0 5px 0; 
        }
        .paymentSubcontainer {
            display: block;
        }
        .collectButton {
            margin-top: 10px;
            padding: 0px;
            width: 100%;
        }
        .localButton {
            width: 100%
        }
        .saleform-title {
            font-size: small;
        }
    }
    @media screen and (min-width: 769px) {
        .saleFormButtonContainer {
            display: flex;
        }
        .saleInformationContainer {
            padding-left: 50px;
        }
        .paymentContainer {
            display: flex;
            margin-bottom: 5px;
        }
        .paymentContainer > div > h5 {
            margin-bottom: 5px; 
        }
        .paymentSubcontainer {
            display: flex;
        }
        .collectButton {
            margin-left: 10px;
            max-width: 150px;
        }
        .localButton {
            width: 170px
        }
        .saleform-title {
            font-size: large;
        }
    }

</style>